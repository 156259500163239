import { createMuiTheme } from "@material-ui/core/styles";

// https://material-ui.com/customization/default-theme/?expand-path=$.palette

let theme = createMuiTheme({
  palette: {
    type: "dark", // https://material-ui.com/customization/palette/#dark-mode
    text: {
      primary: "#f8f8f2",
    },
    background: {
      default: "#282a36",
    },
  },
});

const newBody1 = {
  ...theme.typography.body1,
  [theme.breakpoints.up("sm")]: {
    fontSize: "1.1rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.2rem",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "1.3rem", // no bigger than 1.3 - otherwise the main container with must increase
  },
};
theme.typography.body1 = newBody1;

export default theme;
