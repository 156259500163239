import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import VideoPlayer from "./VideoPlayer";
import cross from "./x.png";
import close from "./close.png";
import IconButton from "@material-ui/core/IconButton";
import { Box } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function VideoDialog(props) {
  const { onClose, open, wisdom } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  if (!open) {
    return null;
  }
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={onClose}
      onEscapeKeyDown={onClose}
      aria-labelledby="video-dialog-title"
      open={open}
      fullScreen={fullScreen}
      fullWidth={true}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <DialogTitle id="video-dialog-title">Quote {wisdom.id}</DialogTitle>
        <IconButton onClick={handleClose}>
          <img src={cross} alt="close" />
        </IconButton>
      </Box>

      <Typography style={{ padding: "1rem", marginBottom: "1rem" }}>
        {wisdom.quote}
      </Typography>

      <VideoPlayer wisdom={wisdom} />
      <DialogActions>
        <IconButton onClick={handleClose}>
          <img src={close} alt="close" />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
}

export default VideoDialog;
