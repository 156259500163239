import React from "react";
import ReactPlayer from "react-player";

class VideoPlayer extends React.Component {
  constructor(props) {
    super(props);
    //console.log("VidePlayer constructor props", props);
    this.playerRef = React.createRef();
    const url = props.wisdom.url;
    let seekTo = null;
    if (url && url.includes("twitch")) {
      const timestamp = url.slice(-9);
      let hours, minutes, rest, seconds;
      [hours, rest] = timestamp.split("h");
      [minutes, rest] = rest.split("m");
      seconds = rest.split("s")[0];
      seekTo =
        parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(seconds);
    }
    //console.log("constructor set state, seekTo", seekTo);
    this.state = { seekTo };
  }
  onDuration = (duration) => {
    //console.log("duration", duration);
    const { seekTo } = this.state;
    if (seekTo) {
      //console.log("duration - seekTo", seekTo);
      this.playerRef.current.seekTo(seekTo, "seconds");
    }
  };
  render() {
    const { wisdom } = this.props;
    //console.log(wisdom);
    if (!wisdom.url) {
      return null;
    }
    const url = wisdom.url;

    return (
      <div style={{ position: "relative", paddingTop: "56.25%" }}>
        <ReactPlayer
          ref={this.playerRef}
          url={url}
          controls
          onDuration={this.onDuration}
          width="100%"
          height="100%"
          style={{ position: "absolute", top: "0", left: "0" }}
        />
      </div>
    );
  }
}

export default VideoPlayer;
