import React from "react";
import { Typography, Fade } from "@material-ui/core";

export default function CheeseWisdom(props) {
  const { wisdom, children } = props;
  return (
    <Fade in={true} timeout={1024}>
      <div className="speech-bubble">
        {wisdom && (
          <Typography align="center">
            <span className="cheese-wisdom">{wisdom}</span>
          </Typography>
        )}
        {children}
      </div>
    </Fade>
  );
}
