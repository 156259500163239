import React from "react";
import Container from "@material-ui/core/Container";
import Fade from "@material-ui/core/Fade";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import "./App.css";
import cheese from "./cheese.svg";
import banner from "./banner.png";
import play from "./play.png";
import CheeseWisdom from "./CheeseWisdom";
import wisdoms from "./CheeseWisdom.json";
import { Box } from "@material-ui/core";
import VideoDialog from "./VideoDialog";

function P({ children, ...rest }) {
  return (
    <Typography paragraph {...rest}>
      {children}
    </Typography>
  );
}

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isThinking: false,
      isDoneThinking: false,
      timeSpentThinkingMs: 0,
      chosenWisdoms: [],
      videoDialogOpen: false,
    };
  }
  handleVideoPlayClick = (e) => {
    e.preventDefault();
    this.setState({ videoDialogOpen: true });
  };
  handleVideoDialogClose = () => {
    this.setState({ videoDialogOpen: false });
  };

  handleCheeseClick = (e) => {
    e.preventDefault();
    const { isThinking, chosenWisdoms } = this.state;
    if (isThinking) {
      return; // handle one click at a time
    }
    this.setState({
      isThinking: true,
      isDoneThinking: false,
    });

    const thinkingTimeAllowed = 1500 + Math.floor(Math.random() * 800);
    const doneThinkingTimer = () => {
      // pick a wisdom that hasn't been chosen "recently"
      const range = Array.from({ length: wisdoms.length }, (x, i) => i);
      const pool = range.filter((i) => !chosenWisdoms.includes(i));
      const chosenWisdom = Math.floor(Math.random() * pool.length);
      let newChosenWisdoms = [...chosenWisdoms, chosenWisdom];
      if (newChosenWisdoms.length > 10) {
        newChosenWisdoms.shift();
      }
      this.setState({
        isThinking: false,
        isDoneThinking: true,
        timeSpentThinkingMs: 0,
        chosenWisdoms: newChosenWisdoms,
      });
    };
    setTimeout(doneThinkingTimer, thinkingTimeAllowed);

    const thinkingTimeUpdateMs = 100;
    const updateTimeSpentThinkingTimer = () => {
      const { isThinking, timeSpentThinkingMs } = this.state;
      const spent = timeSpentThinkingMs + thinkingTimeUpdateMs;
      this.setState({ timeSpentThinkingMs: spent });
      if (isThinking) {
        setTimeout(updateTimeSpentThinkingTimer, thinkingTimeUpdateMs);
      }
    };
    setTimeout(updateTimeSpentThinkingTimer, thinkingTimeUpdateMs);
  };

  render() {
    const {
      isThinking,
      isDoneThinking,
      timeSpentThinkingMs,
      chosenWisdoms,
      videoDialogOpen,
    } = this.state;

    const chosenWisdom = chosenWisdoms[chosenWisdoms.length - 1];
    // const chosenWisdom = 130; // 130 = longest quote (most characters)
    const thinkingMsg = isThinking
      ? ".".repeat(1 + Math.floor(timeSpentThinkingMs / 300))
      : "";

    return (
      <Fade in={true} timeout={1024}>
        <Container maxWidth="sm">
          <Box mt={2} mb={2}>
            <img src={banner} alt="Ask the Cheese" className="banner" />
          </Box>
          <P align="center">Your Magic 8-Ball of cheesy wisdom.</P>
          <Box style={{ textAlign: "center" }} mb={2}>
            <IconButton color="primary" onClick={this.handleCheeseClick}>
              <img
                src={cheese}
                alt="The Cheese"
                className={
                  "cheese-image cheese-image-animation-" +
                  (isThinking ? "working" : "passive")
                }
              />
            </IconButton>
          </Box>
          {(isDoneThinking || isThinking) && (
            <div>
              <CheeseWisdom
                wisdom={
                  isDoneThinking ? wisdoms[chosenWisdom].quote : thinkingMsg
                }
              ></CheeseWisdom>
              {isDoneThinking && (
                <div style={{ textAlign: "center", marginTop: "1rem" }}>
                  <Typography>
                    <em>Quote {wisdoms[chosenWisdom].id}</em>
                  </Typography>
                  {wisdoms[chosenWisdom].url && (
                    <IconButton
                      color="primary"
                      onClick={this.handleVideoPlayClick}
                    >
                      <img src={play} alt="Play Quote Video" />
                    </IconButton>
                  )}
                </div>
              )}
            </div>
          )}
          <VideoDialog
            onClose={this.handleVideoDialogClose}
            open={videoDialogOpen}
            wisdom={wisdoms[chosenWisdom]}
          />
        </Container>
      </Fade>
    );
  }
}
